import AlphaFilters from '@nematis/wp-alpha/assets/js/abstracts/classes/alpha-filters'

AlphaFilters.addFilter('module.rousquille.element.classesChanges', (changes, el, changeInfo) => {
  switch (changeInfo.selector) {
    case '.openPanel':
      changes.add = ['btn-outline-primary']
      break
    default:
      break
  }

  return changes
})

AlphaFilters.addFilter('svgComponent.copyMode', (copyMode, svgComponentEl) => {
  if (svgComponentEl.closest('#rousquilleRoot')) {
    copyMode = 1
  }

  return copyMode
}, 'child')
